import React from 'react'

const CloseButton = (props) => (
  <svg viewBox="0 0 469.785 469.785" {...props}>
    <g>
      <g transform="matrix(1.25 0 0 -1.25 0 45)">
        <g>
          <g>
            <path
              d="M228.294-151.753L367.489-12.558c11.116,11.105,11.116,29.116,0,40.22     c-11.105,11.116-29.104,11.116-40.22,0L188.073-111.533L48.866,27.663c-11.093,11.116-29.116,11.116-40.22,0     c-11.105-11.105-11.105-29.116,0-40.22l139.207-139.196L8.338-291.268c-11.116-11.116-11.116-29.116,0-40.22     c5.552-5.564,12.834-8.34,20.116-8.34c7.27,0,14.552,2.776,20.105,8.34l139.514,139.514l139.196-139.196     c5.564-5.552,12.834-8.34,20.116-8.34c7.27,0,14.552,2.788,20.105,8.34c11.116,11.105,11.116,29.104,0,40.22L228.294-151.753z"
              fill="#FEFEFE"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CloseButton
