import React from 'react'
import PropTypes from 'prop-types'
import styles from './StorySlide.module.scss'
import classNames from 'classnames'

const StorySlide = ({
  body,
  title,
  backgroundImage,
  className,
  cardWithLightBackground,
  videofile,
  defaultBackground,
  withBackgroundVideo,
  videoFitCover,
  videoFitContain,
  textAlignmentFromTop,
  textAlignmentFromBottom,
  textAlignmentCenter,
  titleFontSizeBig,
  contentFontSizeBig,
  bodyDescriptionHtml,
  description,
  publishDate,
  cover,
}) => {
  return (
    <>
      {backgroundImage &&
        !withBackgroundVideo && (
          <article
            className={classNames(
              styles.root,
              cardWithLightBackground && styles.withLightBackground,
              defaultBackground && styles.defaultBackground,
              textAlignmentFromTop && styles.textAlignmentFromTop,
              textAlignmentFromBottom && styles.textAlignmentFromBottom,
              textAlignmentCenter && styles.textAlignmentCenter,
              cover && styles.cover,
              className,
            )}
            style={{ backgroundImage: `url(${backgroundImage.url})` }}
          >
            {publishDate && <p className={styles.date}>{publishDate}</p>}
            <div className={styles.content}>
              <h2 className={classNames(styles.title, titleFontSizeBig && styles.titleFontSizeBig)}>
                {title && title.split('\n').length > 2 ? <pre>{title}</pre> : title}
              </h2>
              {description && <p className={styles.description}>{description}</p>}
              {bodyDescriptionHtml && (
                <div
                  className={classNames(
                    styles.description,
                    contentFontSizeBig && styles.contentFontSizeBig,
                    bodyDescriptionHtml.includes('with-head') && styles.withHead,
                  )}
                  dangerouslySetInnerHTML={{ __html: bodyDescriptionHtml }}
                />
              )}
            </div>
          </article>
        )}

      {videofile &&
        withBackgroundVideo && (
          <article
            className={classNames(
              styles.root,
              cardWithLightBackground && styles.withLightBackground,
              textAlignmentFromTop && styles.textAlignmentFromTop,
              textAlignmentFromBottom && styles.textAlignmentFromBottom,
              textAlignmentCenter && styles.textAlignmentCenter,
              className,
            )}
          >
            <video
              src={videofile.url}
              muted
              autoPlay
              playsInline
              preload="auto"
              loop
              className={classNames(
                styles.video,
                videoFitCover && styles.videoFitCover,
                videoFitContain && styles.videoFitContain,
              )}
            />
            <div className={styles.content}>
              <h2 className={classNames(styles.title, titleFontSizeBig && styles.titleFontSizeBig)}>
                {title && title.split('\n').length > 2 ? <pre>{title}</pre> : title}
              </h2>
              {bodyDescriptionHtml && (
                <div
                  className={classNames(styles.description, contentFontSizeBig && styles.contentFontSizeBig)}
                  dangerouslySetInnerHTML={{ __html: bodyDescriptionHtml }}
                />
              )}
            </div>
          </article>
        )}
    </>
  )
}

StorySlide.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  backgroundImage: PropTypes.object,
  className: PropTypes.string,
  withBackgroundVideo: PropTypes.bool,
  videoFitCover: PropTypes.bool,
  videoFitContain: PropTypes.bool,
  textAlignmentFromTop: PropTypes.bool,
  textAlignmentFromBottom: PropTypes.bool,
  textAlignmentCenter: PropTypes.bool,
  cardWithLightBackground: PropTypes.bool,
  titleFontSizeBig: PropTypes.bool,
  contentFontSizeBig: PropTypes.bool,
  bodyDescriptionHtml: PropTypes.string,
  description: PropTypes.string,
  publishDate: PropTypes.string,
  videofile: PropTypes.object,
  cover: PropTypes.bool,
}

export default StorySlide
