import React, { Component } from 'react'
import Story from 'src/components/Story'
import PageHelmet from 'src/components/PageHelmet'
import withLayout from 'src/components/withLayout'
import { graphql } from 'gatsby'

class StoryTemplate extends Component {
  render() {
    const {
      locale,
      data: {
        story,
        stories: { edges: storiesData },
      },
    } = this.props

    const storiesTitlesArr = storiesData.reduce((acc, item) => {
      acc.push(item.node.slug)
      return acc
    }, [])

    const { title, image, description } = story
    return (
      <>
        <PageHelmet title={title} image={image && image.url} description={description} defer={false} />
        <Story {...story} storiesTitlesArr={storiesTitlesArr} locale={locale} />
      </>
    )
  }
}

export const query = graphql`
  query StoryTemplate($id: String!, $locale: String!) {
    story: datoCmsStory(id: { eq: $id }, locale: { eq: $locale }) {
      id
      title
      description
      publishDate(formatString: "DD.MM")
      slug
      darkSubstrateForBackground
      autoplayDelay
      image {
        id
        alt
        url
      }
      storyCards {
        title
        bodyNode {
          childMarkdownRemark {
            html
          }
        }
        cardWithLightBackground
        backgroundImage {
          id
          url
          alt
        }
        videofile {
          id
          url
        }
        withBackgroundVideo
        videoFitCover
        videoFitContain
        textAlignmentFromTop
        textAlignmentFromBottom
        textAlignmentCenter
        titleFontSizeBig
        contentFontSizeBig
      }
    }
    stories: allDatoCmsStory(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          slug
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'story',
}
export default withLayout(customProps)(StoryTemplate)
