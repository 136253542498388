import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Story.module.scss'
import Swiper from 'react-id-swiper'
import StorySlide from './StorySlide'
import { getBaseUrl } from 'src/utils'
import SocialShare from 'src/components/SocialShare'
import { Link, navigate } from 'gatsby'
import CloseButton from 'src/components/svg/CloseButton'

const isWindowAvailable = () => {
  if (typeof window !== 'undefined' && window) return true
  return false
}

class Story extends Component {
  swiperRef = React.createRef()
  state = {
    currentSlide: 0,
    startX: 0,
    endX: 0,
    minXswipe: 30,
    paginationBulletWidth: 0,
  }

  componentDidMount = () => {
    const { storyCards } = this.props
    if (isWindowAvailable()) {
      window.addEventListener('resize', this.handleResize)
      this.setState({
        paginationBulletWidth: ((window.document.documentElement.clientWidth / storyCards.length) * 0.8).toFixed(),
      })
    }
  }

  componentWillMount = () => {
    isWindowAvailable() && window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.swiperRef.current && this.swiperRef.current.swiper.init()
  }

  handleCrossClick = () => {
    isWindowAvailable() && window.history.back()
  }

  handleNextStoryBtn = () => {
    const { storiesTitlesArr, slug, locale } = this.props
    navigate(`${locale}/story/${storiesTitlesArr[storiesTitlesArr.indexOf(slug) + 1]}`)
  }

  handlePrevStoryBtn = () => {
    const { storiesTitlesArr, slug, locale } = this.props
    navigate(`${locale}/story/${storiesTitlesArr[storiesTitlesArr.indexOf(slug) - 1]}`)
  }

  handleTouchStart = (e) => {
    this.setState({ startX: e.touches[0].clientX })
  }

  handleTouchMove = (e) => {
    this.setState({ endX: e.touches[0].clientX })
  }

  handleTouchEnd = () => {
    const { startX, endX, minXswipe } = this.state
    const { storiesTitlesArr, slug } = this.props

    let xDelta = startX - endX

    if (Math.abs(xDelta) > minXswipe && xDelta !== startX) {
      if (xDelta < 0) {
        this.handlePrevStoryBtn()
      }
      if (xDelta > 0 && storiesTitlesArr.indexOf(slug) !== storiesTitlesArr.length - 1) {
        this.handleNextStoryBtn()
      }
    }
    if (xDelta === startX) {
      if (typeof window !== 'undefined' && xDelta < window.innerWidth / 2) {
        this.handlePrevStoryBtn()
      } else this.handleNextStoryBtn()
    }
  }

  render() {
    const {
      title,
      description,
      image,
      storyCards,
      publishDate,
      slug,
      locale,
      storiesTitlesArr,
      darkSubstrateForBackground,
      autoplayDelay,
    } = this.props
    const { paginationBulletWidth } = this.state

    const flatteredStoryCards = storyCards.map((item) => {
      return { ...item, bodyDescriptionHtml: item.bodyNode.childMarkdownRemark.html }
    })
    const preparedSlides = [
      {
        title: title,
        description: description,
        backgroundImage: image,
        publishDate: publishDate,
        cardWithLightBackground: darkSubstrateForBackground,
      },
      ...flatteredStoryCards,
    ]

    const params = {
      slidesPerView: preparedSlides.length,
      containerClass: 'stories-slider',
      centeredSlides: true,
      grabCursor: true,
      autoplay: {
        delay: Number(autoplayDelay),
        disableOnInteraction: false,
        stopOnLastSlide: true,
      },
      slideToClickedSlide: true,
      on: {
        slideChangeTransitionStart: () => {
          if (this.swiperRef.current.swiper.activeIndex) {
            this.setState({ currentSlide: this.swiperRef.current.swiper.activeIndex })
          }
          if (this.swiperRef.current.swiper.isBeginning) {
            this.setState({ currentSlide: 0 })
          }
        },
        reachEnd: () => {
          this.swiperRef.current.swiper.autoplay.stop()
          if (storiesTitlesArr.indexOf(slug) !== storiesTitlesArr.length - 1) {
            setTimeout(this.handleNextStoryBtn, this.props.autoplayDelay)
          }
        },
        touchStart: () => {
          this.swiperRef.current.swiper.autoplay.stop()
          if (typeof window !== 'undefined') {
            window.document.body.classList.add('pauseAnimation')
          }
        },
        touchEnd: () => {
          this.swiperRef.current.swiper.autoplay.start()
          if (typeof window !== 'undefined') {
            window.document.body.classList.remove('pauseAnimation')
          }
        },
      },
      pagination: {
        el: '.stories-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }

    const slideAnimationDuration = `.swiper-pagination-bullet-active:after{
      width: ${paginationBulletWidth}px;
      left: -${paginationBulletWidth}px;
      animation-duration: ${autoplayDelay / 1000}s !important; 
    }

  @keyframes fade-in-right {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(${paginationBulletWidth}px);
    }
  }
    
    .pauseAnimation *:after {
      animation-play-state: paused !important;
    }
    .swiper-pagination-bullet {
      width: ${paginationBulletWidth}px;}
    `

    return (
      <article className={styles.root}>
        <div className={styles.inner}>
          <style>{slideAnimationDuration}</style>
          <Swiper {...params} ref={this.swiperRef} className="swiper-no-swiping">
            {preparedSlides.map((card, i) => <StorySlide {...card} key={i} cover={i === 0 ? true : false} />)}
          </Swiper>
        </div>
        {this.state.currentSlide === preparedSlides.length - 1 &&
          storiesTitlesArr.indexOf(slug) !== storiesTitlesArr.length - 1 && (
            <div
              className={styles.buttonnext}
              onMouseUp={this.handleNextStoryBtn}
              onTouchStart={this.handleTouchStart}
              onTouchMove={this.handleTouchMove}
              onTouchEnd={this.handleTouchEnd}
            />
          )}
        {this.state.currentSlide === 0 &&
          storiesTitlesArr.indexOf(slug) !== 0 && (
            <div
              className={styles.buttonprev}
              onMouseUp={this.handlePrevStoryBtn}
              onTouchStart={this.handleTouchStart}
              onTouchMove={this.handleTouchMove}
              onTouchEnd={this.handleTouchEnd}
            />
          )}

        <div className={styles.actions}>
          <div className={styles.share}>
            <SocialShare isWhite title={title} url={`${getBaseUrl()}${locale}/story/${slug}`} locale={locale} />
          </div>

          <Link to="/" className={styles.cross}>
            <CloseButton className={styles.cross} height="28px" width="28px" />
          </Link>
        </div>
      </article>
    )
  }
}

Story.propTypes = {
  locale: PropTypes.string,
}

export default Story
